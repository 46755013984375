import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {useEffect} from 'react';
import {View} from 'react-native';
import {Avatar, Card, Text} from 'react-native-paper';
import {Link} from 'react-router-native';
import {planStore} from '../../stores/PlansStore';

const LeftContent = props => <Avatar.Icon {...props} icon="folder"/>

export const ListPlans = observer(() => {
  useEffect(() => {
    planStore.getPlans();
  }, []);


  // const plansStore = useContext(PlansStoreContext);
  console.log(planStore.plan.title);

  return (
    <View>
      {planStore.plans.map(plan => (
        <Link to={`/plans/${plan.id}/`} key={plan.id}>
          <Card>
            <Card.Title title={plan.title} subtitle={plan.start} left={LeftContent}/>
            <Card.Content>
              <Text variant="titleLarge">{plan.title}</Text>
              <Text variant="bodyMedium">{plan.description}</Text>
            </Card.Content>
            <Card.Cover source={{uri: 'https://picsum.photos/700'}}/>
          </Card>
        </Link>
      ))}
    </View>
  );
});
