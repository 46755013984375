import {Appbar} from 'react-native-paper';
import {SignIn} from './SignIn';
import {Link} from 'react-router-native';

const NavTop = () => (

  <Appbar.Header>
    <Link to={'/plans/'}>
      <Appbar.BackAction/>
    </Link>
    <Appbar.Content title="PlanIt"/>
    <Appbar.Action icon="calendar" onPress={() => {
    }}/>
    <Appbar.Action icon="magnify" onPress={() => {
    }}/>
    <SignIn/>
  </Appbar.Header>

);

export default NavTop;