import {Image, View} from 'react-native';
import {Text} from 'react-native-paper';


export const FourOhFour = () => {
  return (
    <View>
      <Text>404</Text>
      <Image
        source={{uri: 'https://i.giphy.com/media/SsOLnh6OTU3RR4IXuq/giphy.webp'}}
        style={{width: '100%', height: '100%'}}
      />
    </View>
  )
}
