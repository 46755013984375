import * as React from 'react';
import {StyleSheet} from 'react-native';
import {Appbar, FAB} from 'react-native-paper';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useNavigate} from 'react-router-native';

export const BOTTOM_APPBAR_HEIGHT = 80;
const MEDIUM_FAB_HEIGHT = 56;

const NavBottom = () => {
  const {bottom} = useSafeAreaInsets();
  const navigate = useNavigate();

  return (
    <Appbar
      style={[
        styles.bottom,
        {
          height: BOTTOM_APPBAR_HEIGHT + bottom,
        },
      ]}
      safeAreaInsets={{bottom}}
    >
      <Appbar.Action icon="archive" onPress={() => {
      }}/>
      <Appbar.Action icon="email" onPress={() => {
      }}/>
      <Appbar.Action icon="label" onPress={() => {
      }}/>
      <Appbar.Action icon="delete" onPress={() => {
      }}/>
      <FAB
        mode="flat"
        size="medium"
        icon="plus"
        onPress={() => {
          // Navigate to the new plan
          navigate('/plans/new');
        }}
        style={[
          styles.fab,
          {top: (BOTTOM_APPBAR_HEIGHT - MEDIUM_FAB_HEIGHT) / 2},
        ]}
      />
    </Appbar>
  );
};

const styles = StyleSheet.create({
  bottom: {},
  fab: {
    position: 'absolute',
    right: 16,
  },
});

export default NavBottom;