import {action, computed, makeObservable, observable} from 'mobx';
import Plan from './Plan';
import Constants from 'expo-constants';
import Cookies from 'js-cookie';
import Invitation from './Invitation';
import dayjs from 'dayjs';

export const NEW_PLAN_ID = 'new';

const apiUrl = Constants.expoConfig.extra.apiUrl;

class PlansStore {

  constructor() {
    makeObservable(this);
  }

    @observable
      plan: Plan = new Plan(NEW_PLAN_ID, 'Exciting Plan!', 'Cody\'s house', 'This is an exciting plan!', dayjs(new Date()).format(), []);

    @action
    setPlanInitSummary(newSummary) {
      this.plan.init_summary = newSummary;
    }

    @action
    setPlanTitle(newTitle) {
      this.plan.title = newTitle;
    }

    @action
    setPlanLocation(newLocation) {
      this.plan.location = newLocation;
    }

    @action
    setPlanDescription(newDescription) {
      this.plan.description = newDescription;
    }

    @action
    setPlanStartDatetime(date, time) {
      // The datepicker creates a datetime object already, we just need to update the time with our data from timepicker.
      date.date.setHours(time.hours, time.minutes, 0, 0);
      let d = dayjs(date.date)
      this.plan.start = d.format();
      this.plan.start_human = d.format('MMMM D, YYYY h:mm A');
      return this.plan.start;
    }

    @action
    addEmailInvitation(email) {
      this.plan.invitations.push(new Invitation(null, null, email, null, false, null, null, email));
    }

    @observable
      invitationEmails: string[] = [];

    @action
    getInvitationEmails() {
      this.plan.invitations.forEach(i => {
        fetch(i.invitee, {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
        })
          .then(response => response.json())
          .then(json => {
            this.invitationEmails.push(json.email);
          })
          .catch(error => {
            console.error(error);
          }).then();
      });
      console.log('emails', this.invitationEmails);
    }

    @observable
      plans: Array<Plan> = [];

    @action
    getPlans(): Array<Plan> {
      console.log('Getting plans!');
      fetch(`${apiUrl}/plans/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
        .then(response => response.json())
        .then(json => {
          this.plans = json.results.map((p: any) => new Plan(p.id, p.name, p.location, p.description, p.start, p.invitations));
        })
        .catch(error => {
          console.error(error);
        });

      return this.plans;
    }

    @action
    getPlan(id?: string): Plan {
      // New plan? Return the new plan template
      if (id === NEW_PLAN_ID) {
        return this.plan;
      }

      // try {
      console.log('Fetching plan!')
      fetch(`${apiUrl}/plans/${id}/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      })
        .then(response => response.json())
        .then(json => {
          this.plan = new Plan(json.id, json.name, json.location, json.description, json.start, json.invitations.map((i: any) => new Invitation(i.id, i.plan, i.invitee, i.invited_by, i.accepted, i.created_at, i.updated_at, i.email)));
          console.log(JSON.stringify(this.plan));
          // return this.plan;
        })
        .catch(error => {
          console.error(error);
        });
      // } catch (e) {
      //   console.error(e);
      //   throw new Error(`Plan with id ${id} not found`);
      // }
      return this.plan;
    }

    @action
    setPlan(plan: Plan) {
      this.plan = plan;
    }

    @computed
    get inviteListUI() {
      if (!this.plan.invitations) {
        console.log('got no invites');
        return []
      }

      const invites = this.plan.invitations.map(i => i.id);
      return invites;
    }

    @action
    save(plan: Plan) {
      fetch(`${apiUrl}/plans/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: JSON.stringify({
          name: plan.title,
          description: plan.description,
          start: plan.start,
          location: plan.location,
        }),
      })
      // TODO: Make this line conditional on the response from the server, send an alert if it fails
      this.plans.push(plan);
    }

    @action
    update(plan: Plan) {
      fetch(`${apiUrl}/plans/${plan.id}/`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: JSON.stringify({
          name: plan.title,
          description: plan.description,
          start: plan.start,
          invitations: plan.invitations,
          location: plan.location,
        }),
      })
      this.plans = this.plans.map(p => p.id === plan.id ? plan : p);
    }

    @action
    upsert(plan: Plan) {
      if (this.plans.map(p => p.id).includes(plan.id)) {
        console.log('updating plan', plan);
        this.update(plan);
      } else {
        console.log('saving plan', plan);
        this.save(plan);
      }
      if (plan.invitations.length > 0) {
        // Send invitations
        plan.invitations.forEach(invitation => {

          if (invitation.email) {
            fetch(`${apiUrl}/users/email/${invitation.email}/`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken'),
              },
            }).then(response => {
              if (response.ok) {
                return response.json()
              } else if (response.status === 404) {
                // Create the user
                fetch(`${apiUrl}/users/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken'),
                  },
                  body: JSON.stringify({
                    email: invitation.email,
                    username: invitation.email,
                  }),
                }).then(response => response.json()).then(json => {
                  fetch(`${apiUrl}/plans/${plan.id}/invitations/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    body: JSON.stringify({
                      plan: `${apiUrl}/plans/${plan.id}/`,
                      invitee: `${apiUrl}/users/${json.id}/`,
                      invited_by: `${apiUrl}/users/96de4c09-2d1b-4a0f-999c-bc07431b56e4/`,
                    }),
                  })
                  return json

                })
              }
            })
              .catch(error => {
                console.log('eerrrrrin')
                console.error(error);
              });
          }
        })
      }
    }

    @action
    generatePlan(): Plan {
      console.log('Generating plan!');
      fetch(`${apiUrl}/chatgpt/generate_plan/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: JSON.stringify({
          user_input: this.plan.init_summary,
        }),
      }).then(response => response.json())
        .then(json => {
          this.plan = new Plan(json.id, json.name, json.location, json.description, json.start, json.invitations.map((i: any) => new Invitation(i.id, i.plan, i.invitee, i.invited_by, i.accepted, i.created_at, i.updated_at, i.email)));
          this.plans.push(this.plan);
          console.log(JSON.stringify(this.plan));
          // return this.plan;
        })
        .catch(error => {
          console.error(error);
        });
      return this.plan;
    }

}

export const planStore = new PlansStore();
