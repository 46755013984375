import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import {makeRedirectUri, useAuthRequest, useAutoDiscovery} from 'expo-auth-session';
import {Button, Platform, Text, View} from 'react-native';
import Constants from 'expo-constants';
import {Link} from '../routes/routing.web';

WebBrowser.maybeCompleteAuthSession();

const useProxy = false; // Platform.select({ web: false, default: true });
const redirectUri = makeRedirectUri({
  scheme: 'planit',
  preferLocalhost: true,
  isTripleSlashed: true,
});


export const SignIn = () => {
  const apiUrl = Constants.expoConfig.extra.apiUrl;
  const clientUrl = Constants.expoConfig.extra.clientUrl;
  const discovery = useAutoDiscovery(`https://auth.planit.zone/.well-known/openid-configuration?tanantId=f57b25a6-dab7-4115-4a98-682dd8b03a70&redirect_url=${clientUrl}/`);

  const [request, result, promptAsync] = useAuthRequest(
    {
      clientId: 'e9fdb985-9173-4e01-9d73-ac2d60d1dc8e',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      redirectUri
    },
    discovery
  );

  if (Platform.OS === 'web') {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Link to={`${apiUrl}/oidc/authenticate/`}>
                    Sign In
        </Link>
      </View>
    );
  }
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <Button title="Sign In" disabled={!request} onPress={() => promptAsync({useProxy})}/>
      {result && <Text>{JSON.stringify(result, null, 2)}</Text>}
    </View>
  );
}
