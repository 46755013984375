import React from 'react';
import {observer} from 'mobx-react-lite';
// @ts-ignore
import {Route, Router, Routes} from './routes/routing';
import {Dimensions, ScrollView, StyleSheet, View} from 'react-native';
import {PaperProvider} from 'react-native-paper';
import {ListPlans} from './modules/plans/Plans';
import {EditPlan} from './modules/plans/Edit';
import {FourOhFour} from './modules/FourOhFour';
import NavTop from './modules/NavTop';
import NavBottom, {BOTTOM_APPBAR_HEIGHT} from './modules/NavBottom';

// https://callstack.github.io/react-native-paper/docs/guides/theming/#creating-dynamic-theme-colors
const theme = {
  version: 3,
  colors: {
    'primary': 'rgb(135, 82, 0)',
    'onPrimary': 'rgb(255, 255, 255)',
    'primaryContainer': 'rgb(255, 221, 186)',
    'onPrimaryContainer': 'rgb(43, 23, 0)',
    'secondary': 'rgb(150, 73, 0)',
    'onSecondary': 'rgb(255, 255, 255)',
    'secondaryContainer': 'rgb(255, 220, 198)',
    'onSecondaryContainer': 'rgb(49, 19, 0)',
    'tertiary': 'rgb(121, 89, 0)',
    'onTertiary': 'rgb(255, 255, 255)',
    'tertiaryContainer': 'rgb(255, 223, 160)',
    'onTertiaryContainer': 'rgb(38, 26, 0)',
    'error': 'rgb(186, 26, 26)',
    'onError': 'rgb(255, 255, 255)',
    'errorContainer': 'rgb(255, 218, 214)',
    'onErrorContainer': 'rgb(65, 0, 2)',
    'background': 'rgb(255, 251, 255)',
    'onBackground': 'rgb(31, 27, 22)',
    'surface': 'rgb(255, 251, 255)',
    'onSurface': 'rgb(31, 27, 22)',
    'surfaceVariant': 'rgb(241, 224, 208)',
    'onSurfaceVariant': 'rgb(80, 69, 58)',
    'outline': 'rgb(130, 117, 104)',
    'outlineVariant': 'rgb(212, 196, 181)',
    'shadow': 'rgb(0, 0, 0)',
    'scrim': 'rgb(0, 0, 0)',
    'inverseSurface': 'rgb(53, 47, 42)',
    'inverseOnSurface': 'rgb(249, 239, 231)',
    'inversePrimary': 'rgb(255, 184, 101)',
    'elevation': {
      'level0': 'transparent',
      'level1': 'rgb(249, 243, 242)',
      'level2': 'rgb(245, 238, 235)',
      'level3': 'rgb(242, 232, 227)',
      'level4': 'rgb(241, 231, 224)',
      'level5': 'rgb(238, 227, 219)'
    },
    'surfaceDisabled': 'rgba(31, 27, 22, 0.12)',
    'onSurfaceDisabled': 'rgba(31, 27, 22, 0.38)',
    'backdrop': 'rgba(57, 47, 36, 0.4)'
  }
};


const App: React.FC = observer(() => {

  // TODO: Make this responsive to page size changes.
  const windowHeight = Dimensions.get('window').height;

  return (
    <PaperProvider theme={theme}>
      <Router>
        <View style={{flex: 1, maxHeight: windowHeight - BOTTOM_APPBAR_HEIGHT}}>
          <NavTop/>
          <ScrollView style={[styles.scrollView]} showsVerticalScrollIndicator={false}>
            <Routes>
              <Route path="/" element={<ListPlans/>}/>
              <Route path="/plans/" element={<ListPlans/>}/>
              <Route path="/plans/:id" element={<EditPlan/>}/>
              <Route path="*" element={<FourOhFour/>}/>
            </Routes>
          </ScrollView>
        </View>
        <View style={styles.fixedNavBottom}>
          <NavBottom/>
        </View>
      </Router>
    </PaperProvider>
  );
});

export default App;

const styles = StyleSheet.create({
  fixedNavBottom: {
    bottom: 0,
  },
  scrollView: {
    backgroundColor: 'white',
    maxHeight: '95%',
  }
});
