import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
// @ts-ignore
import {Link, useLocation, useNavigate} from '../../routes/routing';
import 'dayjs/locale/es';

import posthog from 'posthog-js';
import {StyleSheet, View} from 'react-native';
import {Button, Card, Chip, Modal, Portal, SegmentedButtons, Text, TextInput} from 'react-native-paper';
import {DatePickerModal, TimePickerModal} from 'react-native-paper-dates';
import {planStore} from '../../stores/PlansStore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import 'react-quill/dist/quill.bubble.css'


export const EditPlan = observer(() => {
  const location = useLocation();

  const plan_id = location.pathname.split('/')[2];
  useEffect(() => {
    planStore.getPlan(plan_id);
    planStore.getInvitationEmails();
  }, []);
  posthog.init('phc_QuAJrZ5P8Vjlnlqq2IRPSWVrriVRoMJfX9a2GI4xnnc', {api_host: 'https://app.posthog.com'})


  const [editView, setEditView] = React.useState('main');
  const [date, setDate] = React.useState(null);
  const [dateVisible, setDateVisible] = React.useState(null);
  const [timeVisible, setTimeVisible] = React.useState(null);
  const [newInvitee, setNewInvitee] = React.useState('');
  const [newInviteeIcon, setNewInviteeIcon] = React.useState('');

  const [visible, setVisible] = React.useState(true);
  const containerStyle = {backgroundColor: 'white', padding: 20};

  const hideModal = () => setVisible(false);
  const generatePlan = () => {
    planStore.generatePlan()
    setVisible(false)
  }

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const navigate = useNavigate();


  if (visible && plan_id === 'new') {
    return (<Portal>
      <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
        <Text>Let PlanIt AI help you plan!</Text>
        <TextInput style={{marginBottom: 20}}
          mode='outlined'
          label="Write a short (or long!) plan summary, including date, time, and location"
          placeholder="Annual Camping Trip, July 4th, 2021, 10:00 AM, Seattle, WA"
          value={planStore.plan.init_summary}
          onChangeText={newSummary => planStore.setPlanInitSummary(newSummary)}
        />
        <Button onPress={generatePlan}>Generate my plan!</Button>
        <Button onPress={hideModal}>Enter plan manually</Button>
      </Modal>
    </Portal>)
  }

  return (
    <View>
      {/*It's kinda confusing to have the edit view be a segment button, but it's also kinda cool.... is it too overwhelming/confusing?*/}
      <SegmentedButtons
        style={{margin: 20}}
        value={editView}
        onValueChange={setEditView}
        buttons={[
          {
            value: 'main',
            label: 'Main',
          },
          {
            value: 'assignments',
            label: 'Assignments',
          },
          {
            value: 'location',
            label: 'Location'
          },
          {
            value: 'chat',
            label: 'Chat'
          },
          {
            value: 'history',
            label: 'History'
          },
        ]}
      />


      <Card mode='contained' style={{margin: 20, padding: 50}}>
        <Card.Title title="Edit Plan"/>
        <Card.Content>
          <TextInput style={{marginBottom: 20}}
            mode='outlined'
            label="Title"
            value={planStore.plan.title}
            onChangeText={newTitle => planStore.setPlanTitle(newTitle)}
            placeholder="Annual Camping Trip"
          />
          <TextInput style={{marginBottom: 20}}
            mode='outlined'
            label="When"
            placeholder="click me!"
            value={planStore.plan.start_human}
            onFocus={() => setDateVisible(true)}
          />
          <DatePickerModal
            locale="en"
            mode="single"
            visible={dateVisible}
            onDismiss={() => {
              setDateVisible(false);
              setTimeVisible(true);
            }}
            date={date}
            onConfirm={(e) => {
              setDate(e)
              setDateVisible(false);
              setTimeVisible(true);
            }}
          />
          <TimePickerModal
            visible={timeVisible}
            onDismiss={() => setTimeVisible(false)}
            onConfirm={(e) => {
              setTimeVisible(false);
              planStore.setPlanStartDatetime(date, e);
            }}
            hours={12}
            minutes={14}
          />
          <TextInput style={{marginBottom: 20}}
            mode='outlined'
            label="Location"
            placeholder="Seattle, WA"
            value={planStore.plan.location}
            onChangeText={newLocation => planStore.setPlanLocation(newLocation)}/>
          {/*<TextInput style={{marginBottom: 20}}*/}
          {/*  mode='outlined'*/}
          {/*  label="Description"*/}
          {/*  placeholder="Just bring yourself!"*/}
          {/*  value={planStore.plan.description}*/}
          {/*  onChangeText={newDescription => planStore.setPlanDescription(newDescription)}*/}
          {/*/>*/}
          {/*  TODO: When coding the rendered version -> https://stackoverflow.com/questions/40952434/how-do-i-display-the-content-of-react-quill-without-the-html-markup#answer-58669661*/}
          <ReactQuill
            style={{background: 'white'}}
            value={planStore.plan.description}
            onChange={newDescription => planStore.setPlanDescription(newDescription)}
            // readOnly={true} // Flip this to true to see the description rendered.. not editable also set theme to bubble
            modules={{
              toolbar: [
                [{header: [1, 2, false]}],
                ['bold', 'italic', 'underline'],
                ['image']
              ]
            }}
            theme="snow"
          />

          <TextInput
            mode='outlined'
            label="Enter email, phone number, or contact..."
            placeholder="friend1@gmail.com, 111-222-3333, Mom..."
            value={newInvitee}
            onSubmitEditing={() => {
              console.log('make a chip!');
              // setInviteList([...inviteList, newInvitee]);
              planStore.addEmailInvitation(newInvitee);
              setNewInvitee('');
              setNewInviteeIcon('');
            }}
            onChangeText={t => {
              setNewInvitee(t);
              if (isValidEmail(t)) {
                setNewInviteeIcon('check');
              } else {
                setNewInviteeIcon('');
              }
            }}
            right={<TextInput.Icon icon={newInviteeIcon}/>}
            style={{marginTop: 20}}
          />
          <View style={styles.row}>
            {planStore.plan.invitations.map((invitee, index) => {
              return <Chip
                key={index}
                icon="email"
                onPress={() => console.log('Pressed')}
              >
                {invitee.email}
              </Chip>
            })}
          </View>

        </Card.Content>
        <Card.Actions>
          <Button onPress={() => {
            // planStore.plan.title = title;
            // planStore.plan.description = description;
            // planStore.plan.start = createStartDatetimestamp().toISOString();
            // planStore.plan.invitations = inviteList.map(invitee => {
            //   return new Invitation(invitee)
            // });
            planStore.upsert(planStore.plan);
            // posthog.capture('Plan Saved', {
            //   user: 'TODO',
            //   id: plan.id,
            //   title: plan.title,
            //   start: plan.start,
            //   description: plan.description,
            //   invitations: plan.invitations
            // })
            navigate('/');
          }}>Save</Button>
        </Card.Actions>
      </Card>
    </View>
  );
});

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});
