import {makeAutoObservable} from 'mobx';

export default class Invitation {
  id: string;
  plan: string;
  invitee: string;
  invitedBy: string;
  accepted: boolean;
  acceptedAt: string;
  updatedAt: string;
  email: string;

  constructor(id: string, plan: string, invitee: string, invitedBy: string, accepted: boolean, acceptedAt: string, updatedAt: string, email: string) {
    this.id = id;
    this.plan = plan;
    this.invitee = invitee;
    this.invitedBy = invitedBy;
    this.accepted = accepted;
    this.acceptedAt = acceptedAt;
    this.updatedAt = updatedAt;
    this.email = email;
    makeAutoObservable(this);
  }
}
